import { useContext, useMemo } from 'react';
import { UserGroups } from '../types';
import AuthContext from '../AuthContext';

interface UseRoleCheckOptions {
  reqUserGroup?: UserGroups,
  skipCheck?: boolean
}

const useUserGroupCheck = ({ reqUserGroup, skipCheck }: UseRoleCheckOptions): boolean => {
  const { isLoggedIn, userGroup } = useContext(AuthContext);

  return useMemo(() => {
    let p = true;

    if (!skipCheck) {
      if (!isLoggedIn) {
        p = false;
      }
      if (reqUserGroup && userGroup !== reqUserGroup) {
        p = false;
      }

      // allow admin always
      if (userGroup === UserGroups.Administrator) {
        p = true;
      }
    }

    return p;
  }, [userGroup, isLoggedIn, reqUserGroup]);
};

export default useUserGroupCheck;
