/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

type BackButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>

function BackButton({ className, children, ...rest }: BackButtonProps) {
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate(-1), [navigate]);
  return (
    <Button className={`font-normal ${className || ''}`} onClick={onClick} {...rest}>{children || 'Zurück'}</Button>
  );
}

export default BackButton;
