import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ExamQuestion } from '../../../features/types';
import AnswerInput from './AnswerInput';
import Button from '../../../components/Button';

export interface QuestionFormProps {
  questions: (ExamQuestion & {answerOptions: {label: string, value: string, correct: boolean}[]})[],
  newSimulation: () => void
}

type FormValues = Record<string, null | string>
type AnswerMap = Record<string, boolean>

function QuestionForm({ questions, newSimulation }: QuestionFormProps) {
  const [answerMap, setAnswerMap] = useState<AnswerMap>({});

  const initialValues = useMemo(() => {
    const values: FormValues = {};
    questions.forEach((q) => {
      values[q.id] = null;
    });

    return values;
  }, [questions]);

  const {
    register, handleSubmit, formState: { dirtyFields, isSubmitted },
  } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  const onSubmit = useCallback((val: FormValues) => {
    const newMap: AnswerMap = {};
    Object.keys(val).forEach((id) => {
      newMap[id] = !!(val[id] && val[id] === questions
        .find((q) => q.id === parseInt(id, 10))?.answers
        .find((a) => a.correct)?.id.toString());

      setAnswerMap(newMap);
    });
  }, [questions]);

  const onClickNewSimulation = useCallback(() => {
    newSimulation();
    window.scrollTo({ top: 0 });
  }, [newSimulation]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div data-test="question-container" className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
        {questions.map((question, index) => (
          <div
            key={question.id}
            className={`mb-2 p-1 pl-2 relative rounded ${isSubmitted && (answerMap[question.id] ? 'bg-green-200' : 'bg-red-200')}`}
          >
            <div className="flex">
              <p className="mr-2 font-bold">{`${index + 1}.`}</p>
              <p>{question.questionText}</p>
            </div>
            <AnswerInput
              options={question.answerOptions}
              register={register}
              id={question.id.toString()}
              showAnswers={isSubmitted}
            />
            {isSubmitted && (
            <div className="absolute right-0 bottom-0">
              {answerMap[question.id] ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d=" M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                </svg>
              )}
            </div>
            )}
          </div>
        ))}
      </div>
      <p className="inline-flex justify-center w-full mt-2">{`Sie haben ${Object.keys(dirtyFields).length} von ${questions.length} Fragen beantwortet.`}</p>
      <hr className="my-6" />
      <div className="flex justify-between mb-3 align-center">
        <h3 className="text-1xl font-bold">Auswertung</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <Button type="submit">Prüfung auswerten</Button>
          <Button type="button" disabled={!isSubmitted} onClick={onClickNewSimulation}>Prüfungssimulation neu starten</Button>
        </div>
      </div>
      <div className={`grid grid-cols-2 md:w-2/3 ${isSubmitted ? '' : 'hidden'}`}>
        <p>Anzahl richtig beantworteter Fragen</p>
        <p>
          {`${Object.values(answerMap).filter((a) => a).length} / ${Object.keys(answerMap).length} 
          (${Math.round((Object.values(answerMap).filter((a) => a).length / Object.keys(answerMap).length) * 100)}%)`}
        </p>
      </div>
    </form>
  );
}

export default QuestionForm;
