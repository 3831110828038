import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, CourseDate } from '../../../features/types';
import Page from '../../../components/Page';
import Table from '../../../components/Table';
import Sort from '../../../utils/Sort';
import FilterButton from '../../../components/filter/FilterButton';
import useCourseDateFilter from '../../../features/hooks/useCourseDateFilter';
import CourseDateFilter from '../../../components/filter/CourseDateFilter';
import FilterStateManager, { FilterSubjectKey } from '../../../features/FilterStateManager';
import TableStateManager, { TableSubjectKey } from '../../../features/TableStateManager';
import Utils from '../../../utils/Utils';
import useSearch from '../../../features/hooks/useSearch';

const useFilterState = FilterStateManager.getHook(
  FilterSubjectKey.courseDatesPublic,
  TableSubjectKey.courseDatesPublic,
);
const useTableState = TableStateManager.getHook<CourseDate>(TableSubjectKey.courseDatesPublic, {
  sortBy: [{ id: 'courseDate', desc: false }],
});

function CourseDates() {
  const navigate = useNavigate();

  const columns = useMemo<Column<CourseDate>[]>(() => ([
    {
      Header: 'Lehrgangsart',
      className: 'w-1/5',
      accessor: 'courseType.name',
    },
    {
      id: 'courseDate',
      Header: 'Termin',
      className: 'w-1/6',
      accessor: (r) => `${dayjs(r.courseDate).format('DD.MM.YYYY, HH:mm')} Uhr`,
      sortType: Sort.sortTableDate,
    },
    {
      Header: 'Lehrgangsanbieter',
      id: 'courseProvider',
      accessor: 'courseProvider.name',
      className: 'w-1/6',
    },
    {
      Header: 'Lehrgangsort',
      id: 'area',
      accessor: 'contactInfo.area.name',
      className: 'w-1/6',
    },
    {
      Header: 'Landkreis',
      id: 'district',
      accessor: (d) => Utils.textOrDash(d.contactInfo.area?.districtName),
      className: 'w-1/6',
    },
  ]), []);

  const { data: dates } = useQuery<ApiResponse<CourseDate[]>>('/course/date?future=true');

  const onRowDetail = useCallback((row: CourseDate) => {
    navigate(`/termine/lehrgang/${row.id}`);
  }, [navigate]);

  const [filterOpen, setFilterOpen] = useState(true);

  const { state: { searchString }, onSearchStringChange } = useFilterState();
  const [rows] = useSearch({ data: dates?.data, searchString });

  const {
    areaVal,
    onChangeArea,
    courseTypeVal,
    onChangeCourseType,
    courseProviderVal,
    onChangeCourseProvider,
    endDate,
    onChangeEndDate,
    startDate,
    onChangeStartDate,
    reset,
    rows: filteredRows,
    districtVal,
    onChangeDistrict,
  } = useCourseDateFilter(useFilterState, rows);

  return (
    <Page title="Lehrgangstermine">
      <div className="inline-flex self-end mb-2">
        <FilterButton setOpen={setFilterOpen} open={filterOpen} />
        <input
          className="border px-3 py-2 focus:outline-none focus:bg-secondary2 bg-secondary"
          value={searchString}
          onChange={onSearchStringChange}
          placeholder="Suche..."
        />
      </div>
      <CourseDateFilter
        data={filteredRows}
        open={filterOpen}
        onChangeCourseProvider={onChangeCourseProvider}
        onChangeArea={onChangeArea}
        onChangeCourseType={onChangeCourseType}
        onChangeStartDate={onChangeStartDate}
        onChangeEndDate={onChangeEndDate}
        reset={reset}
        courseProviderVal={courseProviderVal}
        areaVal={areaVal}
        courseTypeVal={courseTypeVal}
        startDate={startDate}
        endDate={endDate}
        districtVal={districtVal}
        onChangeDistrict={onChangeDistrict}
      />
      <Table
        columns={columns}
        data={filteredRows}
        onRowDetail={onRowDetail}
        useTableState={useTableState}
      />
    </Page>
  );
}

export default CourseDates;
