/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface AnswerInputProps<T extends FieldValues> {
  options: {
    value: string,
    label: string,
    correct: boolean
  }[],
  register: UseFormRegister<T>,
  id: Path<T>,
  showAnswers?: boolean
}

function AnswerInput<T extends FieldValues>({
  options, register, id, showAnswers,
}: AnswerInputProps<T>) {
  return (
    <div className="flex flex-col">
      {options.map((option) => (
        <div key={option.value} className="flex items-start">
          <input
            {...register(id)}
            type="radio"
            id={option.value}
            value={option.value}
            className="mr-4 mt-1.5"
          />
          <label htmlFor={option.value}>
            <span className={`${showAnswers && option.correct ? 'text-green-900 font-bold' : ''}`}>{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default AnswerInput;
