/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import LoadingSpinner from './LoadingSpinner';

interface InputProps<T extends FieldValues> {
  label: string,
  labelHidden?: boolean
  id: Path<T>,
  register: UseFormRegister<T>,
  required?: boolean | undefined,
  options: {
    value: string | number,
    label: string
  }[],
  isLoading?: boolean,
  onSelect?: (value: string) => void,
  placeholder?: string,
  className?: string,
  shrink?: boolean
}

function Select<T extends FieldValues>({
  id,
  label,
  required,
  register,
  options,
  isLoading,
  onSelect,
  placeholder,
  className,
  labelHidden,
  shrink,
}: InputProps<T>) {
  return (
    <div className={`${className} ${!labelHidden ? 'grid mb-3' : ''} grid-cols-1 md:grid-cols-3 ${!shrink && 'w-full'}`}>
      <label htmlFor={id} className={`py-2 pr-3 leading-tight w-1/3 ${labelHidden ? 'hidden' : ''}`}>
        {label}
        {required && '*'}
      </label>
      <div className="w-full flex items-center md:col-span-2">
        <select
          id={id}
          data-test={`select-${id}`}
          className="w-full shadow border py-2 pr-3 leading-tight focus:outline-none focus:bg-secondary2 bg-secondary"
          required={required}
          disabled={isLoading}
          defaultValue=""
          {...
          (
            {
              ...register(id),
              onChange: (v) => {
                register(id).onChange(v);
                if (onSelect) {
                  onSelect(v.target.value);
                }
              },
            }
          )
        }
        >
          {placeholder && <option disabled className="bg-secondary hover:bg-secondary2 text-slate-400" value="">{placeholder}</option> }
          {options.map((option) => (
            <option className="bg-secondary hover:bg-secondary2" value={option.value} key={option.value}>{option.label}</option>
          ))}
        </select>
        <LoadingSpinner className="ml-2 -mr-10" loading={Boolean(isLoading)} />
      </div>
    </div>
  );
}

export default Select;
