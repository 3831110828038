import React from 'react';
import FilterSelect from './FilterSelect';
import { Examination } from '../../features/types';
import { ExaminationFilterHandlerProps } from '../../features/hooks/useExaminationFilter';
import FilterDatePicker from './FilterDatePicker';
import Button from '../Button';

interface ExaminationFilterProps extends Omit<ExaminationFilterHandlerProps, 'onSearchStringChange' | 'searchString'> {
  open: boolean,
  data?: Examination[]
}

function ExaminationFilter({
  open,
  data,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  areaVal,
  onChangeArea,
  examTypeVal,
  onChangeExamType,
  examOfficeVal,
  onChangeExamOffice,
  districtVal,
  onChangeDistrict,
  reset,
}: ExaminationFilterProps) {
  return (
    <div className={`${open ? '' : 'hidden'}`}>
      <div className="mb-3 w-full grid gap-3 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        <FilterDatePicker date={startDate} onChange={onChangeStartDate} placeHolder="Von" />
        <FilterDatePicker date={endDate} onChange={onChangeEndDate} placeHolder="Bis" />
        <FilterSelect<Examination>
          labelKey="examType.name"
          valueKey="examType.id"
          data={data}
          value={examTypeVal}
          onChange={onChangeExamType}
          title="Prüfungsarten"
        />
        <FilterSelect<Examination>
          labelKey="contactInfo.area.name"
          valueKey="contactInfo.area.refId"
          data={data}
          value={areaVal}
          onChange={onChangeArea}
          title="Orte"
        />
        <FilterSelect<Examination>
          data={data}
          labelKey="examinationOffice.name"
          valueKey="examinationOffice.id"
          value={examOfficeVal}
          onChange={onChangeExamOffice}
          title="Prüfungsstellen"
          className="md:mr-0"
        />
        <FilterSelect
          labelKey="contactInfo.area.districtName"
          valueKey="contactInfo.area.districtName"
          data={data}
          value={districtVal}
          onChange={onChangeDistrict}
          title="Landkreise"
        />
      </div>
      <div className="w-full flex justify-end mb-3">
        <Button type="button" onClick={reset}>
          Filter zurücksetzen
        </Button>
      </div>
    </div>
  );
}

export default ExaminationFilter;
