import { useQuery } from 'react-query';
import axios from 'axios';
import { ApiResponse, Config } from '../types';

const useConfigData = (): Record<string, string> => {
  const { data: config } = useQuery<Record<string, string>>(['/config', { usage: 'site' }], async () => {
    const { data } = await axios.get<ApiResponse<Config[]>>('/config');

    const records: Record<string, string> = {};
    data?.data.forEach((c) => {
      records[c.key] = c.value;
    });

    return records;
  }, {
    staleTime: 5 * 60 * 1000,
    onSuccess: (data) => {
      document.querySelector('#favicon')?.setAttribute('href', data['html-meta_favicon']);
      document.querySelector('meta[name=\'description\']')?.setAttribute('content', data['html-meta_description']);
      const title = document.querySelector('title');
      if (title) title.innerText = data['html-meta_title'];
    },
    placeholderData: {
      title: 'Fischerprüfung',
      site_homepage: `
         <p>
          Auf dieser Seite finden Fischereiinteressierte Informationen zur Fischerprüfung und zum
          Erwerb des Fischereischeines in Sachsen-Anhalt.
          Außerdem haben Sie hier eine Möglichkeit, sich zusätzlich zu dem gemäß Fischereigesetz
          vorgeschriebenen Besuch eines Pflichtlehrgangs
          individuell auf die Fischerprüfung vorzubereiten.
          <br />
          Der Erwerb eines Fischereischeines setzt in Sachsen-Anhalt das Bestehen einer
          Fischerprüfung voraus, die man nach dem Besuch eines
          30-stündigen Pflichtlehrgangs zur Vorbereitung auf die Fischerprüfung ablegen kann.
          Solche Lehrgänge werden von zahlreichen
          Anglervereinen und einigen anderen Bildungsträgern angeboten.
          <br />
          Die Online-Anwendung ermöglicht mit dem Ablegen einer simulierten Fischerprüfung
          eine Einschätzung des Standes der erworbenen Kenntnisse.
          Die Prüfungssimulation umfasst insgesamt 60 Fragen, die jeweils 15 Fragen aus den
          Fachgebieten Fischkunde, Gerätekunde, Gewässerkunde
          und Gesetzeskunde beinhalten.
          <br />
          Die &quot;richtige&quot; Prüfung ist nach Abschluss eines Vorbereitungslehrganges bei der
          für den Wohnsitz des Prüflings zuständigen Fischereibehörde (Landkreis bzw. kreisfreie Stadt)
          abzulegen. Dort muss man sich bis spätestens 4 Wochen vor dem Prüfungstermin zur
          Fischerprüfung anmelden. Prüfungstermine und Prüfungsorte
          können ebenfalls dieser Online-Anwendung entnommen werden.
          <br />
          Die Jugend- und die Friedfischfischerprüfung werden durch zahlreiche Anglervereine in Form
          eines Prüfungsgespräches abgenommen. Eine schriftliche Prüfung erfolgt nicht. Informationen
          über Termine der Jugend- und Friedfischfischerprüfung sind in der
          Online-Anwendung ebenfalls ersichtlich.
          <br />
          Weiterführende Informationen zu den Themen Fischerprüfung und Fischereischein finden
          Sie auf der Internetseite des
          <a class="underline" target="_blank" rel="noopener noreferrer" href="https://lvwa.sachsen-anhalt.de/das-lvwa/landwirtschaft-umwelt/agrarwirtschaft-laendliche-raeume-fischerei-forst-und-jagdhoheit/fischerei/fischereischein-und-fischerpruefung/">Landesverwaltungsamtes</a>
          .
        </p>
      `,
    },
  });

  return config || {};
};

export default useConfigData;
