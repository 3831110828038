import React, { HTMLProps } from 'react';

interface SimpleSelectProps extends HTMLProps<HTMLSelectElement> {
  className?: string,
  value: string,
  options: {
    value: string | number,
    label: string,
  }[],
  placeholder?: string,
}

function SimpleSelect({
  id, className, value, onChange, options, placeholder,
}: SimpleSelectProps) {
  return (
    <select
      className={`${className || ''} border py-2 pr-3 focus:outline-none focus:bg-secondary2 bg-secondary`}
      value={value}
      onChange={onChange}
      id={id}
    >
      {placeholder
          && <option disabled className="bg-secondary hover:bg-secondary2 text-slate-400" value="">{placeholder}</option>}
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  );
}

export default SimpleSelect;
