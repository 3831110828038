import { ChangeEventHandler, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { CourseDate } from '../types';
import { DateChangeHandler } from './types';
import { StateHook } from '../FilterStateManager';

export interface CourseDateFilterState {
  courseProviderVal: string,
  areaVal: string,
  courseTypeVal: string,
  startDate: Date | null,
  endDate: Date | null,
  searchString: string,
  districtVal: string,
}

export interface CourseDateFilterHandlerProps extends CourseDateFilterState {
  onChangeCourseProvider: ChangeEventHandler
  onChangeArea: ChangeEventHandler
  onChangeCourseType: ChangeEventHandler
  onChangeStartDate: DateChangeHandler
  onChangeEndDate: DateChangeHandler,
  reset: () => void,
  onSearchStringChange: ChangeEventHandler,
  onChangeDistrict: ChangeEventHandler
}

interface UseCourseDateFilter extends CourseDateFilterHandlerProps{
  rows: CourseDate[],
}

const useCourseDateFilter = (
  useFilterState: StateHook,
  data?: CourseDate[],
): UseCourseDateFilter => {
  const {
    state, onChange, reset, onSearchStringChange,
  } = useFilterState();

  const onChangeStartDate = useCallback<DateChangeHandler>(
    (val) => onChange({ ...state, startDate: val as Date | null }),
    [onChange, state],
  );
  const onChangeEndDate = useCallback<DateChangeHandler>(
    (val) => onChange({ ...state, endDate: val as Date | null }),
    [onChange, state],
  );
  const onChangeCourseProvider = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, courseProviderVal: e.target.value }),
    [state, onChange],
  );
  const onChangeArea = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, areaVal: e.target.value }),
    [state, onChange],
  );
  const onChangeCourseType = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, courseTypeVal: e.target.value }),
    [state, onChange],
  );
  const onChangeDistrict = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, districtVal: e.target.value }),
    [state, onChange],
  );

  const rows = useMemo(() => data?.filter((item) => {
    if (state.areaVal !== '0' && item.contactInfo.area?.refId !== state.areaVal) return false;
    if (state.courseProviderVal !== '0' && item.courseProvider.id.toString() !== state.courseProviderVal) return false;
    if (state.courseTypeVal !== '0' && item.courseType.id.toString() !== state.courseTypeVal) return false;
    if (state.startDate && dayjs(item.courseDate).isBefore(dayjs(state.startDate), 'day')) return false;
    if (state.endDate && dayjs(item.courseDate).isAfter(dayjs(state.endDate), 'day')) return false;
    if (state.districtVal === '-1') {
      return !item.contactInfo.area?.districtName;
    }
    if (state.districtVal !== '0' && state.districtVal !== '-1') {
      return item.contactInfo.area?.districtName === state.districtVal;
    }

    return true;
  }), [state, data]);

  return {
    rows: rows || [],
    courseProviderVal: state.courseProviderVal as string,
    onChangeCourseProvider,
    areaVal: state.areaVal as string,
    onChangeArea,
    courseTypeVal: state.courseTypeVal as string,
    onChangeCourseType,
    startDate: state.startDate as Date | null,
    onChangeStartDate,
    endDate: state.endDate as Date | null,
    onChangeEndDate,
    reset,
    searchString: state.searchString,
    onSearchStringChange,
    districtVal: state.districtVal as string,
    onChangeDistrict,
  };
};

export default useCourseDateFilter;
