import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserGroups } from '../../features/types';
import MenuItemContent from './MenuItemContent';

interface MenuProps {
  title: string,
  items: {
    link: string,
    title: string,
    userGroup?: UserGroups,
    skipCheck?: boolean
  }[],
}

function DropDownMenu({ title, items }: MenuProps) {
  return (
    <div className="flex-1 bg-secondary flex items-center justify-center">
      <div data-test={`menu-container-${title}`} className="flex-1 relative inline-block text-left">
        <Menu>
          {({ open }) => (
            <div data-test={`menu-list-${title}`}>
              <Menu.Button className="w-full p-6 hover:bg-secondary2 flex-1 not:last:border-r-2">{title}</Menu.Button>
              <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute z-50 right-0 w-full mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 shadow-lg outline-none"
                >
                  {items.map(({
                    link, title: linkTitle, userGroup, skipCheck,
                  }) => (
                    <MenuItemContent
                      key={link}
                      userGroup={userGroup}
                      link={link}
                      linkTitle={linkTitle}
                      skipCheck={skipCheck}
                    />
                  ))}
                </Menu.Items>
              </Transition>
            </div>
          )}
        </Menu>
      </div>
    </div>
  );
}

export default React.memo(DropDownMenu);
