import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import ExaminationFilter from '../../../components/filter/ExaminationFilter';
import FilterButton from '../../../components/filter/FilterButton';
import Page from '../../../components/Page';
import Table from '../../../components/Table';
import FilterStateManager, { FilterSubjectKey } from '../../../features/FilterStateManager';
import useExaminationFilter from '../../../features/hooks/useExaminationFilter';
import { ApiResponse, Examination } from '../../../features/types';
import Sort from '../../../utils/Sort';
import useSearch from '../../../features/hooks/useSearch';
import TableStateManager, { TableSubjectKey } from '../../../features/TableStateManager';
import Utils from '../../../utils/Utils';

const useFilterState = FilterStateManager.getHook(
  FilterSubjectKey.examinationDatesPublic,
  TableSubjectKey.examinationDatesPublic,
);
const useTableState = TableStateManager.getHook<Examination>(
  TableSubjectKey.examinationDatesPublic,
  {
    sortBy: [{ id: 'date', desc: false }],
  },
);

function ExaminationDates() {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<ApiResponse<Examination[]>>('/exam/examination?future=true');

  const columns = useMemo<Column<Examination>[]>(() => ([
    {
      Header: 'Prüfungsart', id: 'examType', accessor: 'examType.name', className: 'w-1/6',
    },
    {
      id: 'date',
      Header: 'Termin',
      className: 'w-1/6',
      accessor: (r) => `${dayjs(r.date).format('DD.MM.YYYY, HH:mm')} Uhr`,
      sortType: Sort.sortTableDate,
    },
    {
      Header: 'Prüfungsstelle', id: 'examinationOffice', accessor: 'examinationOffice.name', className: 'w-1/6',
    },
    {
      Header: 'Prüfungsort', id: 'area', accessor: 'contactInfo.area.name', className: 'w-1/6',
    },
    {
      Header: 'Landkreis',
      id: 'district',
      accessor: (e) => Utils.textOrDash(e.contactInfo.area?.districtName),
      className: 'w-1/6',
    },
  ]), []);

  const [filterOpen, setFilterOpen] = useState(true);

  const { state: { searchString }, onSearchStringChange } = useFilterState();
  const [rows] = useSearch({ data: data?.data, searchString });

  const {
    rows: filteredRows,
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    examTypeVal,
    onChangeExamType,
    examOfficeVal,
    onChangeExamOffice,
    areaVal,
    onChangeArea,
    reset,
    districtVal,
    onChangeDistrict,
  } = useExaminationFilter(useFilterState, rows);

  const onRowDetail = useCallback((row: Examination) => {
    navigate(`/termine/pruefung/${row.id}`);
  }, [navigate]);

  return (
    <Page title="Prüfungstermine" loading={isLoading}>
      <div className="inline-flex self-end mb-2">
        <FilterButton setOpen={setFilterOpen} open={filterOpen} />
        <input
          className="border px-3 py-2 focus:outline-none focus:bg-secondary2 bg-secondary"
          value={searchString}
          onChange={onSearchStringChange}
          placeholder="Suche..."
        />
      </div>
      <ExaminationFilter
        open={filterOpen}
        examOfficeVal={examOfficeVal}
        onChangeExamOffice={onChangeExamOffice}
        areaVal={areaVal}
        onChangeArea={onChangeArea}
        examTypeVal={examTypeVal}
        onChangeExamType={onChangeExamType}
        startDate={startDate}
        onChangeStartDate={onChangeStartDate}
        endDate={endDate}
        onChangeEndDate={onChangeEndDate}
        data={filteredRows}
        reset={reset}
        districtVal={districtVal}
        onChangeDistrict={onChangeDistrict}
      />
      <Table
        columns={columns}
        data={filteredRows}
        onRowDetail={onRowDetail}
        useTableState={useTableState}
      />
    </Page>
  );
}

export default ExaminationDates;
