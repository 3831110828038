import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import LogoSt from '../../assets/st_wappen.svg';
import ModernDenken from '../../assets/moderndenken.svg';
import MenuButton from './MenuButton';
import Menu from './Menu';
import AuthContext from '../../features/AuthContext';
import { UserGroups } from '../../features/types';
import useUserGroupCheck from '../../features/hooks/useUserGroupCheck';
import useConfigData from '../../features/hooks/useConfigData';

interface LayoutProps {
  enableCourseDate: boolean | undefined
}

function Layout({ children, enableCourseDate }: PropsWithChildren<LayoutProps>) {
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useContext(AuthContext);
  const isAdmin = useUserGroupCheck({ reqUserGroup: UserGroups.Administrator });
  const config = useConfigData();

  const items = useMemo(() => [
    { link: '/nutzer', title: 'Nutzer', userGroup: UserGroups.Administrator },
    { link: '/konfiguration', title: 'Konfiguration', userGroup: UserGroups.Administrator },
    { link: '/pruefung/fachgebiet', title: 'Fachgebiete', userGroup: UserGroups.Administrator },
    { link: '/pruefung/stelle', title: 'Prüfungsstellen', userGroup: UserGroups.UFB },
    { link: '/pruefung/art', title: 'Prüfungsart', userGroup: UserGroups.Administrator },
    { link: '/lehrgang/art', title: 'Lehrgangsarten', userGroup: UserGroups.Administrator },
    { link: '/lehrgang/anbieter', title: 'Lehrgangsanbieter', userGroup: UserGroups.UFB },
    { link: '/pruefung/frage', title: 'Prüfungsfragen', userGroup: UserGroups.Administrator },
    { link: '/pruefung', title: 'Prüfungen', userGroup: UserGroups.UFB },
    ...enableCourseDate ? [{ link: '/lehrgang/termin', title: 'Lehrgangstermine', userGroup: UserGroups.UFB }] : [],
  ].sort((a, b) => a.title.localeCompare(b.title)), [enableCourseDate]);

  const statistics = useMemo(() => [
    { link: '/statistik', title: 'Übersicht', userGroup: UserGroups.Administrator },
    { link: '/statistik/pruefungsstellen', title: 'Prüfungsstellen', userGroup: UserGroups.Administrator },
    { link: '/statistik/pruefungsfragen', title: 'Prüfungsfragen', userGroup: UserGroups.Administrator },
    { link: '/statistik/pruefungen', title: 'Prüfungen', userGroup: UserGroups.Administrator },
  ], []);

  const dateItems = useMemo(() => [
    { link: '/termine/pruefung', title: 'Prüfungstermine', skipCheck: true },
    { link: '/termine/lehrgang', title: 'Lehrgangstermine', skipCheck: true },
  ], []);

  return (
    <>
      <div className="md:container md:mx-auto px-4">
        <div className="flex-row justify-between mt-2 mb-8 hidden md:flex">
          <img className="w-1/6" alt="Sachsen Anhalt Logo" src={LogoSt} />
          <img className="w-1/4 h-auto self-end" alt="Modern denken" src={ModernDenken} />
        </div>
        <div className="bg-secondary pb-1 pt-1 text-center mb-1 mt-4 md:mt-0">
          <Link to="/">
            <h1 className="text-3xl font-bold">{config.title}</h1>
          </Link>
        </div>
        <div className={`grid grid-cols-1 gap-x-1 mb-12 text-center ${clsx(
          enableCourseDate && !isLoggedIn && 'md:grid-cols-3',
          !enableCourseDate && !isLoggedIn && 'md:grid-cols-2',
          isLoggedIn && isAdmin && 'md:grid-cols-4',
          isLoggedIn && !isAdmin && 'md:grid-cols-3',
        )}`}
        >
          {isLoggedIn && enableCourseDate && <Menu title="Termine" items={dateItems} /> }
          {(!isLoggedIn || !enableCourseDate) && <MenuButton link="/termine/pruefung" title="Prüfungstermine" /> }
          {enableCourseDate && !isLoggedIn && <MenuButton link="/termine/lehrgang" title="Lehrgangstermine" /> }
          <MenuButton link="/pruefung/simulation" title="Prüfungssimulation" />
          {isAdmin && (
            <Menu items={statistics} title="Statistiken" />
          )}
          {isLoggedIn && <Menu title="Administration" items={items} />}
        </div>
        <div className="mb-12">
          {children}
        </div>
      </div>
      <footer>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: config['html-footer_code'] }} />
        <div className="md:container md:mx-auto px-4">
          <hr className="my-3 border-black" />
          <div className="flex justify-between mb-12">
            <p>{`${isAdmin ? process.env.REACT_APP_VERSION : ''}`}</p>
            <div className="grid gap-3 grid-flow-row md:grid-flow-col text-center">
              <a href="https://www.sachsen-anhalt.de/meta/hinweise/">Hinweise</a>
              <a href="https://www.sachsen-anhalt.de/meta/datenschutz/">Datenschutz</a>
              <a href="https://www.sachsen-anhalt.de/meta/kontaktformular/">Kontakt</a>
              <a href="https://www.sachsen-anhalt.de/meta/impressum/">Impressum</a>
              <button
                type="button"
                onClick={() => {
                  if (isLoggedIn) {
                    logout();
                  } else {
                    navigate('/login');
                  }
                }}
              >
                {isLoggedIn ? 'Abmelden' : 'Interner Bereich'}
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Layout;
