import React, { PropsWithChildren } from 'react';
import LoadingSpinner from './LoadingSpinner';

interface PageProps {
  title: string,
  loading?: boolean,
  error?: string | false,
  extraInfo?: string
}

function Page({
  title, children, loading, error, extraInfo,
}: PropsWithChildren<PageProps>) {
  return (
    <div className="flex flex-col">
      <div className="flex-1 border-b-2 border-black flex items-center mb-4">
        <div className="flex-1 flex items-center">
          <h2 className="text-2xl font-bold mr-4">{title}</h2>
          {loading && <LoadingSpinner loading />}
        </div>
        <div>
          {error && <p className="text-red-600">{error}</p>}
          {!error && !loading && extraInfo && <p>{extraInfo}</p>}
        </div>
      </div>
      {children}
    </div>
  );
}

export default Page;
