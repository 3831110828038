import React, { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useUserGroupCheck from '../features/hooks/useUserGroupCheck';
import { UserGroups } from '../features/types';

interface PrivateRouteProps {
  userGroup: UserGroups,
  disabled?: boolean,
  children?: ReactElement
}

function PrivateRoute({ children, userGroup, disabled }: PrivateRouteProps) {
  const passes = useUserGroupCheck({ reqUserGroup: userGroup });
  const location = useLocation();

  if (disabled) {
    return <Navigate replace to="/" />;
  }

  if (!passes) {
    return (
      <Navigate
        replace
        to="/login"
        state={{ referrer: location, reqRole: userGroup }}
      />
    );
  }

  return children || <Outlet />;
}

interface DisabledRouteProps {
  disabled?: boolean,
  children?: ReactElement
}

export function DisabledRoute({ disabled, children }: DisabledRouteProps) {
  if (disabled) {
    return <Navigate replace to="/" />;
  }

  return children || <Outlet />;
}

export default PrivateRoute;
