import React, { Dispatch, SetStateAction, useCallback } from 'react';

interface FilterButtonProps {
  setOpen: Dispatch<SetStateAction<boolean>>,
  open: boolean
}

function FilterButton({ setOpen, open }: FilterButtonProps) {
  const toggle = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, [setOpen]);

  return (
    <button className="mr-3" type="button" onClick={toggle}>
      {open ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 25 25" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
        </svg>
      )}
    </button>
  );
}

export default FilterButton;
