import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import Page from '../../../components/Page';
import Select from '../../../components/Select';
import { ApiResponse, ExamQuestion, ExamType } from '../../../features/types';
import Button from '../../../components/Button';
import QuestionForm from './QuestionForm';
import useConfigData from '../../../features/hooks/useConfigData';
import apiErrorHandler from '../../../utils/apiErrorHandler';

interface GetQuestionMutation {
  id: string | number,
  subjectArea?: boolean
}

interface SelectFormValues {
  type: string
}

function SimulationPage() {
  const { register, handleSubmit, formState: { isSubmitting } } = useForm<SelectFormValues>();
  const config = useConfigData();

  const {
    mutateAsync, isLoading, data: questions,
  } = useMutation(async ({ id, subjectArea }: GetQuestionMutation) => {
    const { data: { data } } = await axios.get<ApiResponse<ExamQuestion[]>>(`/exam/question/rand/${id}?subjectArea=${!!subjectArea}`);

    return data.map((q) => ({
      ...q,
      answerOptions: q.answers.map((a) => (
        { label: a.text, value: a.id.toString(), correct: a.correct }
      )),
    }));
  });

  const onSubmit = useCallback(async ({ type }: {type: string}) => mutateAsync({ id: type === 'all' ? 1 : type, subjectArea: type !== 'all' })
    .catch(apiErrorHandler), []);

  const { data: subjectAreas } = useQuery(['/exam/subjectArea', { usage: 'simulation' }], async () => {
    const { data: { data } } = await axios.get<ApiResponse<ExamType[]>>('/exam/subjectArea?order=name');

    const types = [{ label: 'Komplette Prüfung', value: 'all' }];
    data.forEach((a) => types.push({ label: a.name, value: a.id.toString() }));

    return types;
  });

  return (
    <Page title="Prüfungssimulation" loading={isLoading}>
      <div className="mb-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            {/* eslint-disable-next-line react/no-danger */}
            <div className="mb-3" dangerouslySetInnerHTML={{ __html: config.site_simulation }} />
            <div className="flex justify-end">
              {subjectAreas && subjectAreas.length > 0 && (
                <Select shrink className="mr-2" labelHidden label="Art" id="type" register={register} options={subjectAreas} />
              )}
              <Button data-test="generate" disabled={isSubmitting} type="submit">Prüfung generieren</Button>
            </div>
          </div>
        </form>
        {questions && (
          <QuestionForm
            questions={questions}
            newSimulation={handleSubmit(onSubmit)}
          />
        ) }
      </div>
    </Page>
  );
}

export default SimulationPage;
