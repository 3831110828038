/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

function Button({
  className, disabled, children, ...rest
}: ButtonProps) {
  return (
    <button
      type="button"
      {...rest}
      className={`bg-secondary p-2 ${className || ''} ${disabled ? 'text-gray-400' : ''}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
