import { BehaviorSubject } from 'rxjs';
import { bind } from '@react-rxjs/core';
import { ChangeEvent, useCallback } from 'react';
import TableStateManager, { TableSubjectKey } from './TableStateManager';

interface State extends Record<string, string | number | null | Date> {
  searchString: string,
}

interface GetState {
  state: State,
  onChange: (state: State) => void,
  onSearchStringChange: (e: ChangeEvent<HTMLInputElement>) => void,
  reset: () => void,
}

export type StateHook = () => GetState

// eslint-disable-next-line no-shadow
export enum FilterSubjectKey {
  'user' = 'user',
  'subjectArea' = 'subjectArea',
  'examinationOffice' = 'examinationOffice',
  'examType' = 'examType',
  'courseType' = 'courseType',
  'courseProvider' = 'courseProvider',
  'examQuestion'= 'examQuestion',
  'examination' = 'examination',
  'courseDate' = 'courseDate',
  'examinationDatesPublic' = 'examinationDatesPublic',
  'courseDatesPublic' = 'courseDatesPublic',
}

const examinationFilterDefaultState = {
  searchString: '',
  examOfficeVal: '0',
  areaVal: '0',
  examTypeVal: '0',
  startDate: null,
  endDate: null,
  districtVal: '0',
};

const courseDateFilterDefaultState = {
  searchString: '',
  courseProviderVal: '0',
  areaVal: '0',
  courseTypeVal: '0',
  startDate: null,
  endDate: null,
  districtVal: '0',
};

const courseProviderDefaultState = {
  searchString: '',
  areaVal: '0',
  districtVal: '0',
};

const examOfficeDefaultState = {
  searchString: '',
  areaVal: '0',
  districtVal: '0',
  locked: 'false',
};

class FilterStateManager {
  private subjects: Map<FilterSubjectKey, BehaviorSubject<State>>;

  constructor() {
    this.subjects = new Map<FilterSubjectKey, BehaviorSubject<State>>();

    Object.values(FilterSubjectKey).forEach((key) => {
      this.subjects.set(key, new BehaviorSubject(FilterStateManager.getDefaultState(key)));
    });
  }

  private static getDefaultState(key: FilterSubjectKey): State {
    switch (key) {
      case FilterSubjectKey.examination:
      case FilterSubjectKey.examinationDatesPublic: return examinationFilterDefaultState;
      case FilterSubjectKey.courseDate:
      case FilterSubjectKey.courseDatesPublic: return courseDateFilterDefaultState;
      case FilterSubjectKey.courseProvider: return courseProviderDefaultState;
      case FilterSubjectKey.examinationOffice: return examOfficeDefaultState;
      default: return { searchString: '' };
    }
  }

  public getHook(key: FilterSubjectKey, tableKey: TableSubjectKey): StateHook {
    const subject = this.subjects.get(key);
    if (!subject) throw new Error('subjects not initiated');

    const [useSearch] = bind(subject);

    const useTableState = TableStateManager.getHook(tableKey);

    return () => {
      const state = useSearch();
      const { reset: resetTableState } = useTableState();

      const onChange = useCallback((newState: State): void => {
        subject.next(newState);
        resetTableState();
      }, []);

      const onSearchStringChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...state, searchString: e.target.value });
        resetTableState();
      }, [onChange, state]);

      const reset = useCallback(() => {
        subject.next(FilterStateManager.getDefaultState(key));
        resetTableState();
      }, []);

      return {
        state,
        onChange,
        onSearchStringChange,
        reset,
      };
    };
  }
}

export default new FilterStateManager();
