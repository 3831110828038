import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import Input from '../../components/Input';
import Button from '../../components/Button';

interface PasswortResetForm {
  email: string
}

function ForgotPassword() {
  const navigate = useNavigate();

  const { mutate } = useMutation<void, AxiosError, PasswortResetForm>((data) => axios.post('/passwordReset', data), {
    onSuccess: () => {
      toast('Bitte schauen Sie in Ihr E-Mail Postfach, falls Sie nach 5 Minuten keine E-Mail erhalten haben, probieren Sie es erneut.', { type: 'success', autoClose: 10000 });
      navigate('/login');
    },
    onError: (err) => {
      if (err.response?.status === 429) {
        toast('Bitte warten Sie 5 Minuten bevor Sie einen neuen Reset anfordern.', { type: 'error' });
      }
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<PasswortResetForm>();

  return (
    <Page title="Passwort zurücksetzen">
      <p className="md:w-2/3 mb-6">
        Bitte geben Sie hier Ihre E-Mail ein, um Ihr Passwort zurückzusetzen.
        Sie bekommen dann in den nächsten Minuten ene E-Mail mit
        Anweisung zum Zurücksetzen des Passworts
      </p>
      <form className="md:w-1/3" onSubmit={handleSubmit((d) => mutate(d))}>
        <Input
          label="Email"
          id="email"
          type="text"
          register={register}
          error={errors.email?.message}
          inputProps={{
            autoComplete: 'username',
          }}
        />
        <div className="flex justify-end">
          <Button disabled={isSubmitting} className="inline-flex justify-end" type="submit">Abschicken</Button>
        </div>
      </form>
    </Page>
  );
}

export default ForgotPassword;
