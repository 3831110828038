import React from 'react';
import FilterSelect from './FilterSelect';
import { CourseDate } from '../../features/types';
import FilterDatePicker from './FilterDatePicker';
import Button from '../Button';
import { CourseDateFilterHandlerProps } from '../../features/hooks/useCourseDateFilter';

interface CourseDateFilterProps extends Omit<CourseDateFilterHandlerProps, 'onSearchStringChange' | 'searchString'> {
  open: boolean,
  data?: CourseDate[]
}

function CourseDateFilter({
  open,
  data,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  areaVal,
  onChangeArea,
  courseTypeVal,
  onChangeCourseType,
  courseProviderVal,
  onChangeCourseProvider,
  reset,
  districtVal,
  onChangeDistrict,
}: CourseDateFilterProps) {
  return (
    <div className={`${open ? '' : 'hidden'}`}>
      <div className="mb-3 w-full grid gap-3 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        <FilterDatePicker date={startDate} onChange={onChangeStartDate} placeHolder="Von" />
        <FilterDatePicker date={endDate} onChange={onChangeEndDate} placeHolder="Bis" />
        <FilterSelect<CourseDate>
          labelKey="courseType.name"
          valueKey="courseType.id"
          data={data}
          value={courseTypeVal}
          onChange={onChangeCourseType}
          title="Lehrgangsarten"
        />
        <FilterSelect<CourseDate>
          labelKey="contactInfo.area.name"
          valueKey="contactInfo.area.refId"
          data={data}
          value={areaVal}
          onChange={onChangeArea}
          title="Orte"
        />
        <FilterSelect<CourseDate>
          data={data}
          labelKey="courseProvider.name"
          valueKey="courseProvider.id"
          value={courseProviderVal}
          onChange={onChangeCourseProvider}
          title="Lehrgangsanbieter"
        />
        <FilterSelect
          labelKey="contactInfo.area.districtName"
          valueKey="contactInfo.area.districtName"
          data={data}
          value={districtVal}
          onChange={onChangeDistrict}
          title="Landkreise"
        />
      </div>
      <div className="w-full flex justify-end mb-3">
        <Button type="button" onClick={reset}>
          Filter zurücksetzen
        </Button>
      </div>
    </div>
  );
}

export default CourseDateFilter;
