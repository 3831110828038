import React, {
  createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useMutation } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, User, UserGroups } from './types';
import { LoginDTO } from '../pages/Login';

interface AuthContextDTO {
  user: User | null,
  isLoggedIn: boolean,
  userGroup: UserGroups | null,
  logout: () => void,
  login: (values: LoginDTO) => Promise<void>,
}

const AuthContext = createContext<AuthContextDTO>({
  isLoggedIn: false,
  userGroup: null,
  user: null,
  logout: () => undefined,
  login: async () => undefined,
});

export function AuthProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  axios.interceptors.response.use((res) => res, (error) => {
    if (error.response && error.response.status === 401) {
      setUser(null);
      navigate('/login');
      localStorage.removeItem('st-fischerpruefung:user');
    }
    return Promise.reject(error);
  });

  const { mutate: fetchProfileMutation } = useMutation<ApiResponse<{ data: User }>, AxiosError>(() => axios.get('/profile'), {
    onSuccess: (res) => {
      setUser(res.data.data);
    },
    onError: (error) => {
      if (error.response?.status === 401) {
        setUser(null);
      }
    },
  });

  useEffect(() => {
    const ls = localStorage.getItem('st-fischerpruefung:user');
    if (ls) {
      setUser(JSON.parse(ls));
      fetchProfileMutation();
    }
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('st-fischerpruefung:user', JSON.stringify(user));
    }
  }, [user]);

  const { mutateAsync: logoutMutation } = useMutation(() => axios.post('/logout'));

  const logout = useCallback(() => {
    logoutMutation()
      .then(() => {
        setUser(null);
        localStorage.removeItem('st-fischerpruefung:user');
      });
  }, [setUser, logoutMutation]);

  const { mutateAsync: loginMutation } = useMutation<AxiosResponse<ApiResponse<User>>, AxiosError, LoginDTO>((loginData) => axios.post('/login', loginData));

  const login = useCallback(
    async (values: LoginDTO) => loginMutation(values)
      .then((res) => setUser(res.data.data))
      .then(() => navigate('/'))
      .catch(() => {
        toast('E-Mail oder Passwort falsch!', { type: 'error' });
      }),
    [logoutMutation, setUser, navigate],
  );

  const authValue = useMemo(() => ({
    user,
    isLoggedIn: Boolean(user),
    userGroup: user?.userGroup || null,
    logout,
    login,
  }), [user, login, logout]);

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
