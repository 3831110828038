/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useMemo } from 'react';
import {
  CellProps, Column, Row, useExpanded, useFilters, usePagination, useSortBy, useTable,
} from 'react-table';
import Button from './Button';
import { TableStateHook } from '../features/TableStateManager';

interface TableProps<T extends Record<string, unknown>> {
  columns: Column<T>[],
  data: T[],
  onRowEdit?: (row: T) => void,
  onRowDelete?: (row: T) => void,
  onRowDetail?: (row: T) => void,
  /**
   * Must be memorized!
   * @param row
   */
  extraAction?: (row: Row<T>) => ReactElement | null,
  renderSubComponent?: (row: Row<T>) => ReactElement,
  disablePagination?: boolean,
  rowDeleteDisabled?: (row: Row<T>) => boolean,
  useTableState: TableStateHook<T>,
}

function CellExpand<T extends Record<string, unknown>>(
  { row: { isExpanded, getToggleRowExpandedProps } }: Pick<CellProps<T>, 'row'>,
) {
  return (
    <span {...getToggleRowExpandedProps()}>
      {isExpanded ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      )}
    </span>
  );
}

interface CellActionProps<T extends Record<string, unknown>> extends Pick<CellProps<T>, 'row'>, Pick<TableProps<T>, 'onRowEdit' | 'extraAction' | 'onRowDetail' | 'onRowDelete' |'rowDeleteDisabled'> {}

function CellAction<T extends Record<string, unknown>>({
  row, onRowDelete, onRowDetail, onRowEdit, extraAction, rowDeleteDisabled,
}: CellActionProps<T>) {
  return (
    <div className="flex gap-1 justify-center">
      {onRowEdit && (
      <Button data-test="update" onClick={() => onRowEdit(row.original)} className="m-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          />
        </svg>
      </Button>
      )}
      {onRowDelete && (
      <Button data-test="delete" disabled={rowDeleteDisabled && rowDeleteDisabled(row)} onClick={() => onRowDelete(row.original)} className="m-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </Button>
      )}
      {onRowDetail && (
      <Button onClick={() => onRowDetail(row.original)} className="m-1">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
        </svg>
      </Button>
      )}
      {extraAction && (
        extraAction(row)
      )}
    </div>
  );
}

function Table<T extends Record<string, unknown>>({
  columns: c,
  data,
  onRowEdit,
  onRowDelete,
  extraAction,
  renderSubComponent,
  disablePagination,
  onRowDetail,
  rowDeleteDisabled,
  useTableState,
}: TableProps<T>): ReactElement {
  const columns = useMemo<Column<T>[]>(() => {
    if (renderSubComponent) {
      return [
        {
          Header: () => null,
          id: 'expander',
          // is only called once
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: ({ row }: CellProps<T>) => <CellExpand row={row} />,
        },
        ...c,
      ];
    }
    return c;
  }, [c]);

  const pageSizes = useMemo(() => [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: data.length, label: 'Alle' },
  ], [data]);

  const { tableState, onChangeTableState } = useTableState();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageOptions,
    prepareRow,
    state: { pageIndex, pageSize },
    page,
    visibleColumns,
  } = useTable(
    {
      autoResetSortBy: false,
      columns,
      data,
      initialState: tableState,
      autoResetPage: true,
      useControlledState: (state) => React.useMemo(
        () => {
          onChangeTableState(state);
          return state;
        },
        [state, onChangeTableState],
      ),
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    (hooks) => {
      if (onRowEdit || onRowDelete || onRowDetail) {
        hooks.visibleColumns.push((column) => {
          const aktionen = {
            id: 'actions',
            Header: 'Aktionen',
            // is only called once
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: ({ row }: CellProps<T>) => (
              <CellAction<T>
                row={row}
                onRowDelete={onRowDelete}
                onRowEdit={onRowEdit}
                onRowDetail={onRowDetail}
                rowDeleteDisabled={rowDeleteDisabled}
                extraAction={extraAction}
              />
            ),
            className: 'w-1/12',
          };

          return [aktionen, ...column].map((col) => ({ ...col, className: `border ${col.className || ''}` }));
        });
      }
    },
  );

  return (
    <>
      <div className="w-full overflow-x-auto">
        <table {...getTableProps()} className="border border-collapse table-fixed min-w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(
                    { ...column.getSortByToggleProps(), className: `border ${column.className || ''}` },
                  )}
                  >
                    <div className="flex justify-between mx-1">
                      {column.render('Header')}
                      <span>
                        {column.isSorted && (column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                          </svg>
                        ))}

                        {(!column.isSorted && column.canSort) && (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </>
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const props = row.getRowProps();
              return (
                <React.Fragment key={props.key}>
                  <tr {...props}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className="border p-1">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderSubComponent && renderSubComponent(row)}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {!disablePagination && (
        <div className="flex items-center justify-end">
          <div className="mt-3 grid gap-2 grid-cols-9 md:w-1/2">
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </Button>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </Button>
            <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </Button>
            <div className="col-span-2 w-full flex items-center justify-center">
              <span>
                {`Seite ${pageIndex + 1} von ${pageOptions.length}`}
              </span>
            </div>
            <select
              data-test="page-size-select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="col-span-3 w-full shadow border py-2 pr-3 leading-tight focus:outline-none focus:bg-secondary2 bg-secondary"
            >
              {pageSizes.map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
}

export default Table;
