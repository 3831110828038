import { BehaviorSubject } from 'rxjs';
import { bind } from '@react-rxjs/core';
import { useCallback } from 'react';
import { TableState } from 'react-table';

type State<T extends Record<string, unknown> = Record<string, unknown>> = Partial<TableState<T>>

interface GetState<T extends Record<string, unknown>> {
  tableState: State<T>,
  onChangeTableState: (state: State<T>) => void,
  reset: () => void,
}

export type TableStateHook<T extends Record<string, unknown>> = () => GetState<T>

// eslint-disable-next-line no-shadow
export enum TableSubjectKey {
  'user' = 'user',
  'subjectArea' = 'subjectArea',
  'examinationOffice' = 'examinationOffice',
  'examType' = 'examType',
  'courseType' = 'courseType',
  'courseProvider' = 'courseProvider',
  'examQuestion'= 'examQuestion',
  'examination' = 'examination',
  'courseDate' = 'courseDate',
  'courseDatesPublic' = 'courseDatesPublic',
  'examinationDatesPublic' = 'examinationDatesPublic',
  'configuration' = 'configuration',
  'statistics' = 'statistics',
  'statisticsExamination' = 'statisticsExamination',
  'statisticsQuestions' = 'statisticsQuestions',
  'statisticsOffice' = 'statisticsOffice',
}

class TableStateManager {
  private subjects: Map<TableSubjectKey, BehaviorSubject<State>>;

  constructor() {
    this.subjects = new Map<TableSubjectKey, BehaviorSubject<State>>();

    Object.values(TableSubjectKey).forEach((key) => {
      this.subjects.set(key, new BehaviorSubject<State>({}));
    });
  }

  public getHook<T extends Record<string, unknown>>(
    key: TableSubjectKey,
    initialState?: State<T>,
  ): TableStateHook<T> {
    const subject = this.subjects.get(key);
    if (!subject) throw new Error('subjects not initiated');

    if (initialState) {
      subject.next(initialState);
    }

    const [useSearch] = bind(subject);

    return () => {
      const tableState = useSearch();

      const onChangeTableState = useCallback((newState: State<T>): void => {
        subject.next(newState);
      }, []);

      const reset = useCallback(() => {
        subject.next(initialState || {});
      }, []);

      return {
        tableState,
        onChangeTableState,
        reset,
      };
    };
  }
}

export default new TableStateManager();
