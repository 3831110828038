import React from 'react';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import { Slide, toast, ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { Subscribe } from '@react-rxjs/core';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthProvider } from './features/AuthContext';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import('react-toastify/dist/ReactToastify.css');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import('react-datepicker/dist/react-datepicker.css');

// eslint-disable-next-line import/prefer-default-export
export const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:4000';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/api';
} else {
  axios.defaults.baseURL = 'http://localhost:4000';
}
axios.defaults.withCredentials = true;

axios.interceptors.response.use((res) => res, (error) => {
  if (error.response && error.response.status === 409) {
    toast(error.response.data.error.message, { type: 'error' });
  }
  return Promise.reject(error);
});

const defaultQueryFn = async ({ queryKey }: {queryKey: Readonly<unknown[]>}) => {
  const { data } = await axios.get(`${queryKey[0]}`);
  return data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: true,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Subscribe>
            <App />
          </Subscribe>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      pauseOnHover
      transition={Slide}
    />
  </React.StrictMode>,
);

registerLocale('de', de);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
