export default class Utils {
  private static decimals = 1;

  static fracToPercent(num: number): number {
    return Math.round(num * 100 * (10 ** Utils.decimals)) / (10 ** Utils.decimals);
  }

  static textOrDash(text: string | undefined | null | number): string {
    if (text) {
      return `${text}`;
    }
    return '-';
  }

  static withHttps(url: string): string {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  }
}
