/* eslint-disable react/jsx-props-no-spreading */
import React, { HTMLProps } from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface InputProps<T extends FieldValues> {
  label: string,
  id: Path<T>,
  type: string,
  register: UseFormRegister<T>,
  required?: boolean | undefined,
  error?: string,
  inputProps?: HTMLProps<HTMLInputElement>
  defaultValue?: string | number
}

function Input<T extends FieldValues>({
  id, type, label, register, required, error, inputProps, defaultValue,
}: InputProps<T>) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <label htmlFor={id} className="leading-tight mt-2">
        {label}
        {required && '*'}
      </label>
      <div className="w-full col-span-2">
        <input
          data-test={id}
          type={type}
          id={id}
          className="shadow border w-full py-2 px-3 focus:outline-none focus:bg-secondary2 bg-secondary"
          {...inputProps}
          {...register(id)}
          defaultValue={defaultValue}
        />
        <p className="text-red-500 m-0 mb-3 h-6">{error}</p>
      </div>
    </div>
  );
}

export default Input;
