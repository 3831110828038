import React from 'react';
import { Link } from 'react-router-dom';

interface MenuButtonProps {
  link: string,
  title: string
}

function MenuButton({ link, title }: MenuButtonProps) {
  return (
    <Link
      data-test={`menu-${title}`}
      to={link}
      className="bg-secondary p-6 hover:bg-secondary2 flex-1 not:last:border-r-2"
    >
      {title}
    </Link>
  );
}

export default MenuButton;
