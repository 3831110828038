import DatePicker from 'react-datepicker';
import React from 'react';
import { DateChangeHandler } from '../../features/hooks/types';

interface FilterDatePickerProps {
  date: Date | null,
  onChange: DateChangeHandler,
  placeHolder: string
}

function FilterDatePicker({ date, onChange, placeHolder }: FilterDatePickerProps) {
  return (
    <DatePicker
      selected={date}
      className="max-w-full border py-2 px-3 focus:outline-none focus:bg-secondary2 bg-secondary min-w-full"
      onChange={onChange}
      dateFormat="dd.MM.yyyy"
      wrapperClassName="max-w-fit min-w-full"
      placeholderText={placeHolder}
      isClearable
      clearButtonClassName="after:bg-inherit after:text-black after:content-['×'] after:w-1 after:h-1 after:text-xl after:font-bold"
      locale="de"
      nextMonthButtonLabel="Nächster Monat"
      previousMonthButtonLabel="Vorheriger Monat"
      timeCaption="Uhrzeit"
      portalId="datepicker-root"
    />
  );
}

export default FilterDatePicker;
