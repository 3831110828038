/* eslint-disable @typescript-eslint/ban-types */
import dayjs, { Dayjs } from 'dayjs';
import { IdType, Row } from 'react-table';

type DateLike = string | Date | Dayjs;

type CompareResult = 0 | 1 | -1;

export default class Sort {
  static dates(a: DateLike, b: DateLike): CompareResult {
    const dateA = dayjs(a);
    const dateB = dayjs(b);

    if (dateA.isBefore(dateB)) return -1;
    if (dateA.isAfter(dateB)) return 1;

    return 0;
  }

  static sortTableDate<T extends object>(rowA: Row<T>, rowB: Row<T>, id: IdType<T>): CompareResult {
    const dateStringA = (rowA.original as never)[id] as string;
    const dateStringB = (rowB.original as never)[id] as string;

    const dateA = dayjs(dateStringA);
    const dateB = dayjs(dateStringB);
    if (dateA.isSame(dateB, 'second')) {
      return 0;
    }
    if (dateA.isAfter(dateB, 'second')) {
      return 1;
    }
    return -1;
  }
}
