export interface ApiResponse<T> {
  data: T
}

export interface Base extends Record<string, unknown>{
  createdAt: string,
  updatedAt: string,
  id: number
}

// eslint-disable-next-line no-shadow
export enum UserGroups {
  Administrator = 'Administrator',
  UFB = 'Untere Fischereibehörde'
}

export interface UserParams {
  name: string,
  email: string,
  userGroup: UserGroups,
  politicalAreaRefId: string | null,
}

export type User = UserParams & Base & {
  area: PoliticalAreaRef | null
};

export interface SubjectAreaParams {
  name: string,
  locked: boolean
}

export type SubjectArea = SubjectAreaParams & Base;

export interface CourseTypeParams {
  name: string,
  useInStatistics: boolean,
  courseDatesEnabled: boolean,
}

export type CourseType = CourseTypeParams & Base;

export interface PoliticalAreaRef {
  refId: string,
  name: string,
  areaType: string,
  districtName?: string,
}

export interface BaseContactModelParams {
  name: string,
  eventInformation: string | null
  politicalAreaRefId: string,
  email: string | null,
  website: string | null,
  phone: string | null,
  fax: string | null,
  additionalInformation: string | null,
  address: string,
  locked?: boolean
}

export type CourseProviderParams = {
  name: string,
  eventInformation: string | null,
  contactInfo: ContactInfoParams,
  locked?: boolean
};
export type ExaminationOfficeParams = BaseContactModelParams;

export type BaseContactModel = Omit<BaseContactModelParams, 'email' | 'website' | 'phone' | 'fax' | 'area'> & Base & {
  contact: {
    email: string | null,
    website: string | null,
    phone: string | null,
    fax: string | null,
  },
  area: PoliticalAreaRef,
  locked: boolean,
};

export type CourseProvider = Base & {
  id: number,
  name: string,
  contactInfo: ContactInfo,
  eventInformation: string | null,
  locked: boolean
};

export type CourseDate = Omit<CourseDateParams, 'courseTypeId' | 'courseProviderId' | 'contactInfo'> & Base & {
  courseType: CourseType,
  courseProvider: CourseProvider,
  area: PoliticalAreaRef,
  contactInfo: ContactInfo
}

export type CourseDateParams = {
  id: number,
  createdAt: string,
  updatedAt: string,
  name:string,
  courseDate: string,
  courseEndDate: string,
  additionalInformation: string | null,
  courseTypeId: number,
  courseProviderId: number,
  contactInfo: ContactInfoParams
}

export type ExaminationOffice = BaseContactModel;

export interface ExamTypeParams {
  name: string,
  subjectAreas: {
    id: number,
    numQuestions: number,
  }[],
  onlyDates: boolean,
}

export interface ExamTypeFormParams {
  name: string,
  subjectAreas: {
    id: number,
    numQuestions: number,
    enabled: boolean,
  }[],
  onlyDates: boolean,
}

export interface ExamType extends Base{
  name: string,
  subjectAreas: {
    id: number,
    numQuestions: number,
    name: string,
    locked: boolean
  }[],
  onlyDates: boolean,
}

export interface ExamQuestionAnswersParams {
  text: string,
  correct: boolean
}

export interface ExamQuestionAnswers extends ExamQuestionAnswersParams{
  id: number,
}

export interface ExamQuestionParams {
  questionText: string,
  subjectAreaId: number,
  answers: ExamQuestionAnswersParams[],
  locked: boolean,
}

export interface ExamQuestion extends Base, ExamQuestionParams{
  answers: ExamQuestionAnswers[],
  subjectArea: {
    name: string,
    id: number,
  },
  updatedBy?: {
    name: string,
    id: number
  }
}

export interface ContactInfoParams {
  politicalAreaRefId: string | null,
  email: string | null,
  website: string | null,
  phone: string | null,
  fax: string | null,
  additionalInformation: string | null,
  address: string | null,
}

export interface ContactInfo {
  contact: {
    email: string | null,
    website: string | null,
    phone: string | null,
    fax: string | null,
  },
  area: PoliticalAreaRef | null,
  additionalInformation: string | null,
  address: string | null,
}

export interface ExaminationParams {
  title: string,
  date: string,
  examTypeId: number,
  examinationOfficeId: number,
  contactInfo: ContactInfoParams,
  additionalInformation: string | null,
}

export interface Examination extends Base, Omit<ExaminationParams, 'questions' | 'contactInfo'> {
  questions: ExamQuestion[],
  examType: {
    id: number,
    name: string,
    onlyDates: boolean,
  },
  examinationOffice: {
    id: number,
    name: string,
    politicalAreaRef: PoliticalAreaRef
  }
  answers: ExamQuestionAnswers[],
  updatedBy: {
    name: string,
    id: number
  }
  contactInfo: ContactInfo
}

export type SystemEnvironment = 'dev' | 'stage' | 'prod';

export interface ConfigParams extends Record<string, unknown>{
  value: string,
  title: string,
}

export interface Config extends ConfigParams{
  key: string,
  updatedAt: string,
  richText: boolean,
}

export type StatisticsOverview = {
  terminatedExams: number;
  scheduledExams: number;
  participantQuotas: {
    courseTypeId: number | null,
    courseTypeName: string | null,
    participantCount: number;
    participantPassCount: number;
    participantFailQuota: number;
  }[],
  terminatedExamsPerExaminationOffice: {
    examinationOfficeId: number;
    examinationOffice: string,
    examCount: number
  }[];
  missingStatisticsPerExamPerExaminationOffice?: {
    examinationOfficeId: number;
    examinationOffice: string;
    exams: {
      examinationId: number;
      examinationDate: Date,
      examinationTitle: string;
    }[]
  }[]
}

export type ExaminationOfficeStatistic = {
  examinationOfficeId: number;
  examinationOffice: string;
  terminatedExams: number;
  participantCount: number;
  passedExams: number;
  passedExamQuota: number;
  participantQuotas: {
    courseTypeId: number | null,
    courseTypeName: string | null,
    participantCount: number;
    participantPassCount: number;
    participantFailQuota: number;
  }[],
}

export type ExamQuestionStatistics = {
  id: number;
  questionText: string;
  examReferences: number;
  participantCount: number;
  falseAnswerQuota: number;
  simulationReferences: number;
}

export type ExamStatistics = {
  examId: number;
  examTitle: string;
  participantPassCount: number;
  participantsFailQuota: number;
  examinationOffice: {
    id: number,
    name: string,
  },
  participants: number,
  participantQuotas: {
    courseTypeId: number | null,
    courseTypeName: string | null,
    participantCount: number;
    participantPassCount: number;
    participantFailQuota: number;
  }[],
}

export type ExamParticipants = Base & {
  participantCount: number;
  participantPassCount: number;
  courseTypeId: number | null,
  examination: Examination,
  examinationId: number
}

export interface StatisticsEdit {
  examinationParticipants: ExamParticipants[],
  questions: {
    examinationId: number,
    examQuestionId: number,
    correctAnswerCount: number,
  }[],
  examination: Examination
}
