import React from 'react';
import Utils from '../utils/Utils';

interface ItemProps {
  label: string,
  value: string | undefined | null,
}

export function Item({ label, value }: ItemProps) {
  return (
    <>
      <p className="font-bold">{label}</p>
      <p className="col-span-2 whitespace-pre-line">{Utils.textOrDash(value)}</p>
    </>
  );
}

interface WebsiteEmailProps {
  website: string | undefined | null,
  mail: string | undefined | null,
}

export function WebsiteEmailItem({ mail, website }: WebsiteEmailProps) {
  return (
    <>
      <p className="font-bold">Website</p>
      <a
        className={`col-span-2 whitespace-pre-line ${website ? 'underline' : ''}`}
        href={website ? Utils.withHttps(website) : ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        {Utils.textOrDash(website)}
      </a>
      <p className="font-bold">E-Mail</p>
      <a
        className={`col-span-2 whitespace-pre-line ${mail ? 'underline' : ''}`}
        href={`mailto://${mail}` || ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        {Utils.textOrDash(mail)}
      </a>
    </>
  );
}
