/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import Input from '../components/Input';
import AuthContext from '../features/AuthContext';
import useUserGroupCheck from '../features/hooks/useUserGroupCheck';
import { UserGroups } from '../features/types';

export interface LoginDTO {
  email: string,
  password: string,
}

function Login() {
  const location = useLocation();
  const state = location.state as Record<string, string> | undefined;

  const { register, handleSubmit } = useForm<LoginDTO>();

  const { login } = useContext(AuthContext);
  const passes = useUserGroupCheck({ reqUserGroup: state?.reqRole as UserGroups });

  if (passes) {
    return <Navigate to={state?.referrer || '/'} replace />;
  }

  return (
    <Page title="Anmelden">
      <form className="flex flex-col md:w-1/3" onSubmit={handleSubmit((values) => login(values))}>
        <Input<LoginDTO>
          label="E-Mail"
          id="email"
          type="email"
          register={register}
          required
          inputProps={{
            autoComplete: 'username',
          }}
        />
        <Input<LoginDTO>
          label="Passwort"
          id="password"
          type="password"
          register={register}
          required
          inputProps={{
            autoComplete: 'current-password',
          }}
        />
        <Link className="underline inline-flex justify-end -mt-6 mb-4" to="/passwort/forgot">Passwort vergessen</Link>
        <button data-test="submit" type="submit" className="self-end mt-2 bg-secondary p-3 font-bold">Anmelden</button>
      </form>
    </Page>
  );
}

export default Login;
