import { useMemo } from 'react';
import { matchSorter } from 'match-sorter';

interface UseSearchParams<T> {
  data: T[] | undefined | null,
  searchString: string
}

type UseSearch<T> = [T[]]

const useSearch = <T extends Record<string, unknown>>(
  { data, searchString }: UseSearchParams<T>,
): UseSearch<T> => {
  const rows = useMemo(() => {
    const preFilteredRows = data;
    if (preFilteredRows) {
      if (searchString) {
        return matchSorter(
          preFilteredRows,
          searchString,
          {
            keys: Object.keys(preFilteredRows[0]),
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          },
        );
      }
      return preFilteredRows;
    }

    return [];
  }, [data, searchString]);

  return [rows];
};

export default useSearch;
