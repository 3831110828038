import { ChangeEventHandler, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { Examination } from '../types';
import { StateHook } from '../FilterStateManager';
import { DateChangeHandler } from './types';

export interface FilterState {
  examOfficeVal: string,
  areaVal: string,
  examTypeVal: string,
  startDate: Date | null,
  endDate: Date | null,
  searchString: string,
  districtVal: string,
}

export interface ExaminationFilterHandlerProps extends FilterState {
  onChangeExamOffice: ChangeEventHandler
  onChangeArea: ChangeEventHandler
  onChangeExamType: ChangeEventHandler
  onChangeStartDate: DateChangeHandler
  onChangeEndDate: DateChangeHandler,
  onChangeDistrict: ChangeEventHandler
  reset: () => void,
  onSearchStringChange: ChangeEventHandler
}

interface UseExaminationFilter extends ExaminationFilterHandlerProps{
  rows: Examination[],
}

const useExaminationFilter = (
  useFilterState: StateHook,
  data?: Examination[],
): UseExaminationFilter => {
  const {
    state, onChange, reset, onSearchStringChange,
  } = useFilterState();

  const onChangeStartDate = useCallback<DateChangeHandler>(
    (val) => onChange({ ...state, startDate: val as Date | null }),
    [onChange, state],
  );
  const onChangeEndDate = useCallback<DateChangeHandler>(
    (val) => onChange({ ...state, endDate: val as Date | null }),
    [onChange, state],
  );
  const onChangeExamOffice = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, examOfficeVal: e.target.value }),
    [state, onChange],
  );
  const onChangeArea = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, areaVal: e.target.value }),
    [state, onChange],
  );
  const onChangeExamType = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, examTypeVal: e.target.value }),
    [state, onChange],
  );
  const onChangeDistrict = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ ...state, districtVal: e.target.value }),
    [state, onChange],
  );

  const rows = useMemo(() => data?.filter((item) => {
    if (state.areaVal !== '0'
      && item.contactInfo.area?.refId !== state.areaVal) return false;
    if (state.examOfficeVal !== '0'
      && item.examinationOffice.id.toString() !== state.examOfficeVal) return false;
    if (state.examTypeVal !== '0'
      && item.examType.id.toString() !== state.examTypeVal) return false;
    if (state.startDate
      && dayjs(item.date).isBefore(dayjs(state.startDate), 'day')) return false;
    if (state.endDate
      && dayjs(item.date).isAfter(dayjs(state.endDate), 'day')) return false;
    if (state.districtVal === '-1') {
      return !item.contactInfo.area?.districtName;
    }
    if (state.districtVal !== '0' && state.districtVal !== '-1') {
      return item.contactInfo.area?.districtName === state.districtVal;
    }

    return true;
  }), [state, data]);

  return {
    rows: rows || [],
    examOfficeVal: state.examOfficeVal as string,
    onChangeExamOffice,
    areaVal: state.areaVal as string,
    onChangeArea,
    examTypeVal: state.examTypeVal as string,
    onChangeExamType,
    startDate: state.startDate as Date | null,
    onChangeStartDate,
    endDate: state.endDate as Date | null,
    onChangeEndDate,
    districtVal: state.districtVal as string,
    onChangeDistrict,
    reset,
    onSearchStringChange,
    searchString: state.searchString,
  };
};

export default useExaminationFilter;
