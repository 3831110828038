import { useParams } from 'react-router-dom';
import React from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { ApiResponse, Examination } from '../../../features/types';
import Page from '../../../components/Page';
import BackButton from '../../../components/BackButton';
import { Item, WebsiteEmailItem } from '../../../components/Item';

function ExaminationDetailsPage() {
  const { id } = useParams<{id: string}>();

  const { data, isLoading, isError } = useQuery<ApiResponse<Examination>>([`/exam/examination/${id}`, 'public']);

  return (
    <Page title={data ? data.data.examType.name : ''} loading={isLoading} error={isError && 'Termin konnte nicht geladen werden!'}>
      <div className="w-2/3">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
          <Item label="Termin" value={`${dayjs(data?.data.date).format('DD.MM.YYYY HH:mm')} Uhr`} />
          <Item label="Prüfungsort" value={data?.data.contactInfo.area?.name} />
          <Item label="Prüfungsstelle" value={data?.data.examinationOffice.name} />
          <Item label="Prüfungsart" value={data?.data.examType.name} />
        </div>
        <hr className="my-2" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
          <Item label="Adresse" value={data?.data.contactInfo.address} />
          <WebsiteEmailItem
            website={data?.data.contactInfo.contact.website}
            mail={data?.data.contactInfo.contact.email}
          />
          <Item label="Telefon" value={data?.data.contactInfo.contact.phone} />
          <Item label="Fax" value={data?.data.contactInfo.contact.fax} />
          <Item label="Kontaktinfo" value={data?.data.contactInfo.additionalInformation} />
        </div>
        <hr className="my-2" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
          <Item label="Informationen" value={data?.data.additionalInformation} />
        </div>
      </div>
      <div className="flex justify-end">
        <BackButton className="w-1/4 mt-3" />
      </div>
    </Page>
  );
}

export default ExaminationDetailsPage;
