import React, { ChangeEventHandler, useMemo } from 'react';
import { get, uniqBy } from 'lodash';
import { Path } from 'react-hook-form';
import Utils from '../../utils/Utils';
import SimpleSelect from '../SimpleSelect';

interface FilterSelectProps<T extends Record<string, unknown>> {
  labelKey: Path<T>,
  valueKey: Path<T>,
  data: T[] | undefined,
  value: string,
  onChange: ChangeEventHandler<HTMLSelectElement>,
  title: string,
  className?: string
}

interface SelectOption {
  label: string,
  value: string,
}

function FilterSelect<T extends Record<string, unknown>>({
  labelKey, valueKey, data, value, onChange, title, className,
}: FilterSelectProps<T>) {
  const uniqOptions: SelectOption[] = useMemo(() => {
    const uniqData = uniqBy(data, valueKey);
    const items: SelectOption[] = [];

    items.push({ label: `Alle ${title}`, value: '0' });
    uniqData.forEach((d) => {
      items.push({
        label: Utils.textOrDash(get(d, labelKey) as string),
        value: get(d, valueKey) as string || '-1',
      });
    });

    return items.sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  return (
    <SimpleSelect
      value={value}
      onChange={onChange}
      options={uniqOptions}
      className={className}
    />
  );
}

export default FilterSelect;
