import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

interface ApiError {
  error: {
    message: string
  }
}

const apiErrorHandler = (err: AxiosError<ApiError>): void => {
  if (err.response?.data?.error) {
    toast(err.response?.data?.error?.message, { type: 'error' });
  } else {
    toast('Ein Fehler ist aufgetreten.', { type: 'error' });
  }
};

export default apiErrorHandler;
