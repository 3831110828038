import React from 'react';
import Page from '../components/Page';
import useConfigData from '../features/hooks/useConfigData';

function Home() {
  const config = useConfigData();

  return (
    <Page title="Onlineanwendung – Fischerprüfung in Sachsen-Anhalt">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: config.site_homepage }} />
    </Page>
  );
}

export default Home;
