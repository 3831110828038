import React from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import useUserGroupCheck from '../../features/hooks/useUserGroupCheck';
import { UserGroups } from '../../features/types';

interface MenuItemContentProps {
  userGroup?: UserGroups,
  link: string,
  linkTitle: string,
  skipCheck?: boolean,
}

function MenuItemContent({
  userGroup, link, linkTitle, skipCheck,
}: MenuItemContentProps) {
  const passes = useUserGroupCheck({ reqUserGroup: userGroup, skipCheck });

  if (!passes) return null;

  return (
    <Menu.Item key={link}>
      {({ active }) => (
        <Link
          className={`${active ? 'bg-secondary2' : 'bg-secondary'} flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
          to={link}
        >
          {linkTitle}
        </Link>
      )}
    </Menu.Item>
  );
}

export default MenuItemContent;
