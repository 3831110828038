import React, { lazy, Suspense } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import PrivateRoute, { DisabledRoute } from './components/PrivateRoute';
import { ApiResponse, UserGroups } from './features/types';
import Home from './pages/Home';
import Login from './pages/Login';
import SimulationPage from './pages/pruefung/simulation';
import ExaminationDates from './pages/Termine/pruefung';
import ExaminationDetailsPage from './pages/Termine/pruefung/Details';
import CourseDates from './pages/Termine/lehrgang';
import CourseDateDetailsPage from './pages/Termine/lehrgang/Details';
import ForgotPassword from './pages/passwort/ForgotPassword';
import ChangePassword from './pages/passwort/ChangePassword';
import LoadingSpinner from './components/LoadingSpinner';

const CourseProviderList = lazy(() => import('./pages/lehrgang/anbieter'));
const CreateCourseProvider = lazy(() => import('./pages/lehrgang/anbieter/CreateCourseProvider'));
const EditCourseProvider = lazy(() => import('./pages/lehrgang/anbieter/EditCourseProvider'));

const CourseTypeList = lazy(() => import('./pages/lehrgang/art'));
const CreateCourseType = lazy(() => import('./pages/lehrgang/art/CreateCourseType'));
const EditCourseType = lazy(() => import('./pages/lehrgang/art/EditCourseType'));

const CourseDateList = lazy(() => import('./pages/lehrgang/termin'));
const CreateCourseDate = lazy(() => import('./pages/lehrgang/termin/CreateCourseDate'));
const EditCourseDate = lazy(() => import('./pages/lehrgang/termin/EditCourseDate'));

const UserList = lazy(() => import('./pages/nutzer'));
const CreateUser = lazy(() => import('./pages/nutzer/CreateUser'));
const EditUser = lazy(() => import('./pages/nutzer/EditUser'));

const ExaminationTypeList = lazy(() => import('./pages/pruefung/art'));
const CreateExamType = lazy(() => import('./pages/pruefung/art/CreateExamType'));
const EditExamType = lazy(() => import('./pages/pruefung/art/EditExamType'));

const SubjectAreaList = lazy(() => import('./pages/pruefung/fachgebiet'));
const CreateSubjectArea = lazy(() => import('./pages/pruefung/fachgebiet/CreateSubjectArea'));
const EditSubjectArea = lazy(() => import('./pages/pruefung/fachgebiet/EditSubjectArea'));

const ExaminationOfficeList = lazy(() => import('./pages/pruefung/stelle'));
const CreateExaminationOffice = lazy(() => import('./pages/pruefung/stelle/CreateExaminationOffice'));
const EditExaminationOffice = lazy(() => import('./pages/pruefung/stelle/EditExaminationOffice'));

const ExamQuestionList = lazy(() => import('./pages/pruefung/frage'));
const CreateExamQuestion = lazy(() => import('./pages/pruefung/frage/CreateExamQuestion'));
const EditExamQuestion = lazy(() => import('./pages/pruefung/frage/EditExamQuestion'));

const ExaminationList = lazy(() => import('./pages/pruefung/pruefung'));
const CreateExamination = lazy(() => import('./pages/pruefung/pruefung/CreateExamination'));
const EditExamination = lazy(() => import('./pages/pruefung/pruefung/EditExamination'));
const EditStatistic = lazy(() => import('./pages/pruefung/pruefung/EditStatistic'));

const ConfigListPage = lazy(() => import('./pages/konfiguration'));
const EditConfig = lazy(() => import('./pages/konfiguration/EditConfig'));

const StatisticOverview = lazy(() => import('./pages/statistik'));
const StatisticExamOffice = lazy(() => import('./pages/statistik/pruefungsstellen'));
const StatisticExamQuestion = lazy(() => import('./pages/statistik/pruefungsfragen'));
const StatisticExam = lazy(() => import('./pages/statistik/pruefungen'));

function App() {
  const { data: courseDatesEnabled } = useQuery<ApiResponse<boolean>>('/config/env/courseDatesEnabled', { initialData: { data: false } });

  return (
    <Layout enableCourseDate={courseDatesEnabled?.data}>
      <Suspense fallback={(
        <div className="flex justify-center align-middle w-full">
          <LoadingSpinner loading />
        </div>
      )}
      >
        <Routes>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="nutzer" element={<PrivateRoute userGroup={UserGroups.Administrator} />}>
            <Route index element={<UserList />} />
            <Route path="create" element={<CreateUser />} />
            <Route path="edit/:id" element={<EditUser />} />
          </Route>
          <Route path="pruefung">
            <Route path="fachgebiet" element={<PrivateRoute userGroup={UserGroups.Administrator} />}>
              <Route index element={<SubjectAreaList />} />
              <Route path="create" element={<CreateSubjectArea />} />
              <Route path="edit/:id" element={<EditSubjectArea />} />
            </Route>
            <Route path="art" element={<PrivateRoute userGroup={UserGroups.Administrator} />}>
              <Route index element={<ExaminationTypeList />} />
              <Route path="create" element={<CreateExamType />} />
              <Route path="edit/:id" element={<EditExamType />} />
            </Route>
            <Route path="stelle" element={<PrivateRoute userGroup={UserGroups.UFB} />}>
              <Route index element={<ExaminationOfficeList />} />
              <Route path="create" element={<CreateExaminationOffice />} />
              <Route path="edit/:id" element={<EditExaminationOffice />} />
            </Route>
            <Route path="frage" element={<PrivateRoute userGroup={UserGroups.Administrator} />}>
              <Route index element={<ExamQuestionList />} />
              <Route path="create" element={<CreateExamQuestion />} />
              <Route path="edit/:id" element={<EditExamQuestion />} />
            </Route>
            <Route element={<PrivateRoute userGroup={UserGroups.UFB} />}>
              <Route index element={<ExaminationList />} />
              <Route path="create" element={<CreateExamination />} />
              <Route path="edit/:id" element={<EditExamination />} />
            </Route>
            <Route path="statistik/edit/:id" element={<PrivateRoute userGroup={UserGroups.UFB}><EditStatistic /></PrivateRoute>} />
          </Route>
          <Route path="lehrgang">
            <Route path="art" element={<PrivateRoute userGroup={UserGroups.UFB} />}>
              <Route index element={<CourseTypeList />} />
              <Route path="create" element={<CreateCourseType />} />
              <Route path="edit/:id" element={<EditCourseType />} />
            </Route>
            <Route path="anbieter" element={<PrivateRoute userGroup={UserGroups.UFB} />}>
              <Route index element={<CourseProviderList />} />
              <Route path="create" element={<CreateCourseProvider />} />
              <Route path="edit/:id" element={<EditCourseProvider />} />
            </Route>
            <Route path="termin" element={<PrivateRoute userGroup={UserGroups.UFB} disabled={!courseDatesEnabled?.data} />}>
              <Route index element={<CourseDateList />} />
              <Route path="create" element={<CreateCourseDate />} />
              <Route path="edit/:id" element={<EditCourseDate />} />
            </Route>
          </Route>
          <Route path="konfiguration" element={<PrivateRoute userGroup={UserGroups.UFB} />}>
            <Route index element={<ConfigListPage />} />
            <Route path="edit/:id" element={<EditConfig />} />
          </Route>
          <Route path="statistik" element={<PrivateRoute userGroup={UserGroups.Administrator} />}>
            <Route index element={<StatisticOverview />} />
            <Route path="pruefungsstellen" element={<StatisticExamOffice />} />
            <Route path="pruefungen" element={<StatisticExam />} />
            <Route path="pruefungsfragen" element={<StatisticExamQuestion />} />
          </Route>
          <Route path="passwort">
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="reset" element={<ChangePassword />} />
          </Route>
          <Route path="termine">
            <Route path="pruefung">
              <Route index element={<ExaminationDates />} />
              <Route path=":id" element={<ExaminationDetailsPage />} />
            </Route>
            <Route path="lehrgang" element={<DisabledRoute />}>
              <Route index element={<CourseDates />} />
              <Route path=":id" element={<CourseDateDetailsPage />} />
            </Route>
          </Route>
          <Route path="pruefung/simulation" element={<SimulationPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}
export default App;
